export default {
  data: [
    {
      id: 0,
      name: "Adobe Photoshop",
      tools: " ",
      link: " ",
      image: [require("../assets/img/adobe-photoshop.png")],
    },
    {
      id: 1,
      name: "Adobe Illustrator",
      tools: " ",
      link: " ",
      image: [require("../assets/img/illustrator.png")],
    },
    {
      id: 2,
      name: "HTML 5, CSS 3, Javascript ES9+",
      tools: "Obligatory Web Tools",
      link: " ",
      image: [require("../assets/img/TESAD1.png")],
    },
    {
      id: 2,
      name: "NPM, Yarn and Git",
      tools: "Standart version and package managers",
      link: " ",
      image: [require("../assets/img/gitnpm.png")],
    },
    {
      id: 3,
      name: "Typescript",
      tools: "Better Javascript!",
      link: " ",
      image: [require("../assets/img/typescript.png")],
    },
    {
      id: 4,
      name: "React JS",
      tools: "Web applications with React JS framework",
      link: " ",
      image: [require("../assets/img/react.png")],
    },
    {
      id: 5,
      name: "React Native",
      tools: "Cross-Platform Native Mobile development with React Native",
      link: " ",
      image: [require("../assets/img/react-native.png")],
    },
    {
      id: 6,
      name: "Node JS",
      tools: " Creating RESTfull APIs using Javascript with Node JS",
      link: " ",
      image: [require("../assets/img/node.png")],
    },
    {
      id: 7,
      name: "Next JS",
      tools: "Server-side application rendering with Next JS",
      link: " ",
      image: [require("../assets/img/next.png")],
    },
    {
      id: 8,
      name: "Expo",
      tools: "Libraries for Native Mobile Applications with Expo",
      link: " ",
      image: [require("../assets/img/expo.png")],
    },
    {
      id: 8,
      name: "Electron",
      tools: "Native Desktop Applications using Electron Framework",
      link: " ",
      image: [require("../assets/img/electron.png")],
    },
    {
      id: 9,
      name: "Deno JS",
      tools: "Using Typescript on Server-side with Deno JS",
      link: " ",
      image: [require("../assets/img/deno.png")],
    },
    {
      id: 9,
      name: "Docker",
      tools: "Project Containerization with Docker",
      link: " ",
      image: [require("../assets/img/docker.png")],
    },
    {
      id: 10,
      name: "PHP",
      tools: " Creating Old School fullstack applications with PHP",
      link: " ",
      image: [require("../assets/img/php.png")],
    },
    {
      id: 10,
      name: "Sass",
      tools: "CSS with SUPER powers!",
      link: " ",
      image: [require("../assets/img/1_FeiTcE7xAIKhNrRa-2_oiQ.png")],
    },
    {
      id: 11,
      name: "Wordpress",
      tools: "Complete solution for creating websites and blogs with Wordpress",
      link: " ",
      image: [require("../assets/img/wp.png")],
    },
    {
      id: 12,
      name: "Elementor Pro",
      tools: " Creating awesome layouts in Wordpress with Elementor",
      link: " ",
      image: [require("../assets/img/elementor-logo.png")],
    },
  ],
};
