export default {
  data: [
    {
      id: 0,
      name: "Website - POP 95,1 FM",
      tools: "Wordpress - PHP - Javascript",
      link: "https://www.pop95fm.com.br/",
      image: [require("../assets/img/popradio.jpg")],
    },
    {
      id: 0,
      name: "Website - Sakuravee: Streamer, Influencer",
      tools: "Next JS - React JS - SASS - Node JS",
      link: "https://sakuravee.vercel.app/",
      image: [require("../assets/img/sakuravee.jpg")],
    },
    {
      id: 0,
      name: "Website - Nobilis! Marketing e Gestão",
      tools: "Wordpress - PHP - Javascript",
      link: "https://www.nobilismkt.com/",
      image: [require("../assets/img/nobilis.jpg")],
    },
    {
      id: 0,
      name: "Website - Renata Carvalho Advogada",
      tools: "PHP - SASS - HTML - CSS - Javascript",
      link: "https://renatacarvalhoadvogada.com.br/",
      image: [require("../assets/img/renataadvogada.jpg")],
    },
    {
      id: 0,
      name: "Website - Tonz Tintas",
      tools: "Wordpress - PHP - Javascript",
      link: "https://tonz.com.br/",
      image: [require("../assets/img/tonz.jpg")],
    },
    {
      id: 0,
      name: "Website - Avante Guerreira! Marcinha Silveira",
      tools: "Next JS - React JS - SASS - Node JS",
      link: "https://avanteguerreira.com.br/",
      image: [require("../assets/img/marcinha.jpg")],
    },
    {
      id: 0,
      name: "Website - Shopping Ibituruna",
      tools: "PHP - SASS - HTML - CSS - Javascript",
      link: "http://ibiturunacenter.com.br/",
      image: [require("../assets/img/ibituruna.png")],
    },
    {
      id: 1,
      name: "Website - Divan das Unhas",
      tools: "PHP - SASS - HTML - CSS - Javascript",
      link: "http://divandasunhas.com.br/",
      image: [require("../assets/img/divan.png")],
    },
    {
      id: 2,
      name: "Website - Partner Sports",
      tools: "Wordpress - PHP - Javascript",
      link: "https://partnersports.com.br/",
      image: [require("../assets/img/partner.png")],
    },

    {
      id: 3,
      name: "Franchising Dashboard - Divan das Unhas",
      tools: "PHP - SASS - HTML - CSS - Javascript",
      link: "http://divandasunhas.com.br/",
      image: [require("../assets/img/divan-franquia.png")],
    },
    {
      id: 4,
      name: "Website - Spatium Jeans",
      tools: "Wordpress - PHP - Javascript",
      link: "http://spatium.com.br/",
      image: [require("../assets/img/spatium.png")],
    },
    {
      id: 5,
      name: "Website - Pinheiro Pneus",
      tools: "Wordpress - PHP - Javascript",
      link: "https://lojapinheiropneus.com.br/",
      image: [require("../assets/img/pinheiro.png")],
    },
    {
      id: 6,
      name: "Website - Confeitaria Alzucar",
      tools: "Wordpress - PHP - Javascript",
      link: "https://alzucar.com.br/",
      image: [require("../assets/img/alzucar-2.png")],
    },
    {
      id: 7,
      name: "Sistema de Montagem de Bolo - Confeitaria Alzucar",
      tools: "PHP - HTML - CSS - Javascript",
      link: "https://alzucar.com.br/",
      image: [require("../assets/img/alzucar.png")],
    },
    {
      id: 8,
      name: "Website - Clínica Dr. Custodio",
      tools: "Wordpress - PHP - Javascript",
      link: "https://clinicadrcustodio.com.br/",
      image: [require("../assets/img/custodio.png")],
    },
    {
      id: 9,
      name: "Website - Construtora Dakota",
      tools: "Wordpress - PHP - Javascript",
      link: "https://construtoradakota.com.br/",
      image: [require("../assets/img/dakota.png")],
    },
    {
      id: 10,
      name: "Website - Açaí Tropical Brasil",
      tools: "Wordpress - PHP - Javascript",
      link: "https://acaitropicalbrasil.com.br/",
      image: [require("../assets/img/acai.png")],
    },
    {
      id: 11,
      name: "Website - Tennis Shoes Brasil",
      tools: "Wordpress - PHP - Javascript",
      link: "https://tennisshoesbrasil.com.br/co/",
      image: [require("../assets/img/tennis.png")],
    },
    {
      id: 12,
      name: "Website - Rádio Educadora Moc",
      tools: "Wordpress - PHP - Javascript",
      link: "https://educadoramoc.com/",
      image: [require("../assets/img/educadora.png")],
    },
    {
      id: 13,
      name: "Website - Comando das 7 | Educadora Moc",
      tools: "Wordpress - PHP - Javascript",
      link: "https://comandodas7.educadoramoc.com/",
      image: [require("../assets/img/comando.png")],
    },
    {
      id: 14,
      name: "Website - Petlee",
      tools: "Wordpress - PHP - Javascript",
      link: "https://petlee.com.br/",
      image: [require("../assets/img/petlee.png")],
    },
    {
      id: 15,
      name: "Affiliate Program - Petlee",
      tools: "React - SASS - Javascript",
      link: "https://afiliados.petlee.com.br/",
      image: [require("../assets/img/petlee-2.png")],
    },
    {
      id: 16,
      name: "Selling API - Petlee",
      tools: "Node JS - React - Javascript",
      link: "https://afiliados.petlee.com.br/",
      image: [require("../assets/img/petlee-1.png")],
    },
    {
      id: 17,
      name: "Website - SIAD FASA 2020",
      tools: "Wordpress - PHP - Javascript",
      link: "https://siadfasa.com.br/",
      image: [require("../assets/img/siad.png")],
    },
    {
      id: 18,
      name: "Website - Charger for You",
      tools: "Wordpress - PHP - Javascript",
      link: "http://chargerforyou.com.br",
      image: [require("../assets/img/charger.png")],
    },
    {
      id: 19,
      name: "QR Code Tracking System - Charger for You",
      tools: "PHP - SASS - HTML - CSS - Javascript",
      link: "http://chargerforyou.com.br",
      image: [require("../assets/img/charger-2.png")],
    },
    {
      id: 20,
      name: "Venda Mais - Mobile App SAAS",
      tools: "React - Node JS - Next JS - SASS - Typescript",
      link: "http://vendamais.app",
      image: [require("../assets/img/venda.png")],
    },
    {
      id: 20,
      name: "Venda Mais - Mobile App SASS",
      tools: "React - React Native - Expo - Typescript",
      link: "http://vendamais.app",
      image: [require("../assets/img/venda1.png")],
    },
    {
      id: 21,
      name: "My Portfolio",
      tools: "React - Typescript",
      link: "https://danielbrown.com.br/",
      image: [require("../assets/img/port.png")],
    },
    {
      id: 22,
      name: "QR Direct - QR Code Generator and Campaign tracker",
      tools: "PHP - SASS - HTML - CSS - Javascript",
      link: "https://qr-direct.com/",
      image: [require("../assets/img/qrdir.png")],
    },

    {
      id: 23,
      name: "Personal Project Manager",
      tools: "Electron - React - Typescript",
      link: "",
      image: [require("../assets/img/proj.png")],
    },

    {
      id: 23,
      name: "[WIP] QR Direct: Reloaded",
      tools: "Deno - Next JS - Typescript - Expo",
      link: "https://qr-direct.com/",
      image: [require("../assets/img/qrdir-2.png")],
    },
  ],
};
